
import { computed, defineComponent } from 'vue';
import useCluster, { Cluster } from '@/composables/cluster';
import useWallet from '@/composables/wallet';
const WalletName = {
  Phantom: "Phantom",
  Sollet: "Sollet",
  SolletExtension: "SolletExtension",
  Solflare: "Solflare",
}

export default defineComponent({
  setup() {
    // cluster
    const { cluster, setCluster, getClusterURL } = useCluster();
    const chosenCluster = computed({
      get() {
        return cluster.value;
      },
      set(newVal: Cluster) {
        setCluster(newVal);
      },
    });

    // wallet
    const { getWalletName, setWallet } = useWallet();
    const chosenWallet = computed({
      get() {
        return getWalletName();
      },
      set(newVal: any | null) {
        setWallet(newVal, getClusterURL());
      },
    });

    return {
      Cluster,
      chosenCluster,
      WalletName,
      chosenWallet,
    };
  },
});
